<template>
  <div class="app-select">
    <select
      :disabled="disabled"
      :value="value"
      @change="$emit('change', $event.target.value)"
    >
      <option v-for="(o,index) in options"
        :key="index"
        v-text="o"
      />
    </select>
    <span class="icon app-select__icon" aria-hidden="true">keyboard_arrow_down</span>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
    },
    disabled:{
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style>
.app-select {
  position: relative;
  display: block;
  border-radius: 3px;
  margin: 4px 8px;
  height: 32px;
  z-index: 2;
  border: none;
  color: white;
  background-color: var(--buttons-color);
  min-width: max-content;
}

.app-select__icon {
  position: absolute;
  right: 8px;
  top: 4px;
  z-index: -1;
}

.app-select select {
  padding: 0px 40px 0px 16px;
  font-size: 12pt;
  background: none;
  height: 100%;
  width: 100%;
  color: white;
  appearance: none;
  cursor: pointer;
}

.app-select select option {
  color: black;
}

.app-select select:disabled {
  cursor: not-allowed;
}
</style>