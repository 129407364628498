import appConfig from '@/app-config.js';
import api from '@/api';

export default {
  state() {
    const archs = appConfig.archs;
    let state = {};
    for(const archIndex in archs) {
      const archName = archs[archIndex];
      state[archName] = {
        arch: archName,
        status: 0,
        modified: ''
      }
    }
    return state;
  },
  getters: {
    archStatus: arch => state => state[arch].status,
  },
  mutations: {
    SET_ARCH_LIST(state, archList) {
      for(const archObj of archList)
        state[archObj.arch] = archObj;
    },
    SET_ARCH(state, archObj) {
      state[archObj.arch] = archObj;
    }
  },
  actions: {
    async fetchArchList(ctx) {
      const r = await api.fetchArchList();
      if(r.status === 200)
        ctx.commit('SET_ARCH_LIST', r.data);
      return r;
    },
    async fetchArch(ctx, arch) {
      const r = await api.fetchArch(arch);
      if(r.status === 200)
        ctx.commit('SET_ARCH', r.data);
      return r;
    },
    async updateArch(ctx, archObj) {
      const r = await api.updateArch(archObj);
      if(r.status === 200)
        ctx.commit('SET_ARCH', archObj);
      return r;
    }
  }
}