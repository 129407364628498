<template>
  <div id="app">
    <the-navigation/>
    <router-view/>
    <the-messagebox/>
  </div>
</template>

<script>
import '@/assets/article.css';

import api from '@/api';

import TheNavigation from '@/components/the-navigation.vue';
import TheMessagebox from '@/components/the-messagebox.vue';

export default {
  name: 'app',
  components: {
    TheNavigation,
    TheMessagebox,
  },
  created() {
    api.axios.interceptors.response.use(null, err => {
      if(err.response.status === 401) {
        this.$store.commit('LOGOUT');
        this.$router.push({name: 'login'});
      }
      return Promise.reject(err);
    })
  }
}
</script>

<style>
:root {
  --header-color: #280680;
  --buttons-color: #5e35b1;
  --disabled-button-color: #737373;
  --hover-button-color: #784dcb;
  --active-button-color: #5e35b1;
  --selection-color: #c8bbf1;
  --slider-color: #9781d5;
  --success-color: #00d700;
  --failure-color: #d50000;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

body, button, input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-size: 1rem;
}

.icon {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  width: 24px;
  height: 24px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}

#app {
  display: flex;
  height: 100vh;
  overflow: auto;
}

main {
  flex: 5;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  line-height: 24px;
}

tr {
  border-bottom: 1px solid #aaa;
}

th, td {
  padding: 8px 16px;
  overflow: hidden;
}

.push-right {
  margin-left: auto;
}

.push-bottom {
  margin-top: auto;
}

.inter-area {
  flex: auto;
  min-height: 48px;
  padding: 8px 16px 8px 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
</style>