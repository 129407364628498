const appConfig = Object.freeze({
  /**
   * Regexp for validating user passwords.
   */
  passwordPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,

  /**
   * Regexp for validating file names.
   */
  fileNamePattern: /^[^<>:?"*|/\\]+$/,

  /**
   * Notification autohide timeout.
   *
   * Non-error messages in the message box are closed automatically after
   * specified timeout in milliseconds. Zero value turns of this feature.
   */
  messageAutoHideTimeout: 3000,

  /**
   * Map between architecture indices and names.
   */
  archs: {
    //1: 'atom',
    2: 'atom-sim-gpu',
    3: 'atom-sim-cpu',
    //4: 'linopt',
    //5: 'linopt-gates',
    6: 'linopt-sim',
  },

  /**
   * URLs.
   */
  backendUrl: 'https://rcp.qotlabs.org/api/v0/',
  //backendUrl: 'http://localhost:9191/',

  /**
   * Timeout in milliseconds for waiting a backend response.
   */
  apiTimeout: 30000,

  /**
   * Min, default and max user quotas
   */
  minCPU: 1,      defaultCPU: 100,    maxCPU: 1600,
  minMemory: 1,   defaultMemory: 512, maxMemory: 16384,
  minDisk: 1,     defaultDisk: 50,    maxDisk: 16384,
  minTime: 1,     defaultTime: 600,   maxTime: 86400,
  minPriority: 1, defaultPriority: 1, maxPriority: 100,

  /**
   * Maximum number of messages in the console
   */
  consoleLimit: 200,
});

let AppConfigPlugin = {};
AppConfigPlugin.install = function (Vue, options) {
  Vue.$appConfig = appConfig;
  Vue.prototype.$appConfig = appConfig;
}

export default appConfig;
export { AppConfigPlugin };
