<template>
  <ul class="the-messagebox">
    <li
      v-for="msg in $store.getters.messages"
      :key="msg.id"
      class="the-messagebox__message"
      :class="messageClass(msg.type)"
    >
      {{ msg.text }}
      <span
        class="icon the-messagebox__close-icon"
        aria-hidden="true"
        @click="deleteMessage(msg)"
      >
        close
      </span>
    </li>
  </ul>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  methods: {
    messageClass(type) {
      return 'the-messagebox__message_' + type;
    },
    ...mapMutations({
      deleteMessage: 'DELETE_MESSAGE'
    })
  },
}
</script>

<style>
.the-messagebox {
  position: absolute;
  top: 24px;
  left: 50vw;
  transform: translate(max(-50%, -50vw), 0);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 10;
}

.the-messagebox__message {
  box-shadow: 0 0 8px -2px black;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin: 4px auto;
  padding: 8px 16px;
  line-height: 1.5;
  max-width: 600px;
}

.the-messagebox__close-icon {
  margin-left: auto;
  padding-left: 8px;
  cursor: pointer;
}

.the-messagebox__message_error {
  background-color: var(--failure-color);
  color: white;
}

.the-messagebox__message_notification {
  background-color: var(--success-color);
}
</style>
