<template>
  <label
    class="app-switch"
    :class="{'app-switch_disabled': disabled}"
  >
    <input
      class="app-switch__checkbox"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
      :id="name"
    >
    <span class="app-switch__slider"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name:{
      type: String,
    }
  },
}
</script>

<style>
.app-switch {
  cursor: pointer;
  display: block;
  position: relative;
  width: 40px;
  height: 20px;
}

.app-switch_disabled {
  cursor: not-allowed;
}

.app-switch__checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.app-switch__slider {
  position: absolute;
  background-color: #aaa;
  border-radius: 7px;
  width: 34px;
  height: 14px;
  top: 3px;
  left: 3px;
}

.app-switch__slider::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: -3px;
  top: -3px;
  box-shadow: 0 0 2px;
  background-color: white;
  border-radius: 50%;
  transition: .1s;
}

input.app-switch__checkbox:checked + .app-switch__slider {
  background-color: var(--slider-color);
}

input.app-switch__checkbox:checked + .app-switch__slider::before {
  transform: translateX(20px);
  background-color: var(--buttons-color);
}
</style>