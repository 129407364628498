<template>
  <aside class="sidebar" v-if="isVisible">
    <img class="sidebar__logo" src="@/assets/logo.svg" alt="Logo" />
    <nav id="nav" class="nav">
      <ul>
        <li v-if="!isAdmin">
          <router-link class="nav__item" :to="{name: 'fileList'}">
            <span class="icon" aria-hidden="true">folder_open</span>
            {{ $t('the-navigation.files') }}
          </router-link>
        </li>
        <li v-if="isAdmin">
          <router-link class="nav__item" :to="{name: 'userList'}">
            <span class="icon" aria-hidden="true">people_alt</span>
            {{ $t('the-navigation.users') }}
          </router-link>
        </li>
        <li>
          <router-link class="nav__item" :to="{name: 'taskList'}">
            <span class="icon" aria-hidden="true">assignment</span>
            {{ $t('the-navigation.tasks') }}
          </router-link>
        </li>
        <li>
          <button class="nav__item" @click="isArchsOpened = !isArchsOpened">
            <span class="icon" aria-hidden="true">memory</span>
            {{ $t('the-navigation.archs') }}
            <span class="icon" aria-hidden="true">
              {{
                isArchsOpened ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
              }}
            </span>
          </button>
          <ul v-if="isArchsOpened">
            <li v-for="arch in archs" :key="arch">
              <router-link class="nav__item" :to="{name: arch}">
                # {{ arch }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link class="nav__item" :to="{name: 'settings'}">
            <span class="icon" aria-hidden="true">settings</span>
            {{ $t('the-navigation.settings') }}
          </router-link>
        </li>
        <li>
          <router-link class="nav__item" :to="{name: 'help'}">
            <span class="icon" aria-hidden="true">help_outline</span>
            {{ $t('the-navigation.help') }}
          </router-link>
          <ul v-if="this.$route.name === 'help'">
            <li v-for="item in submenuHelp" :key="item.link">
              <a :href="item.link" class="nav__item"> # {{ item.title }} </a>
            </li>
          </ul>
        </li>
        <li>
          <router-link class="nav__item" :to="{name: 'logout'}">
            <span class="icon" aria-hidden="true">exit_to_app</span>
            {{ $t('the-navigation.logout') }}
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      isArchsOpened: true,
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isVisible() {
      return !this.$route.meta.hideNavigation;
    },
    archs() {
      return Object.values(this.$appConfig.archs);
    },
    submenuHelp() {
      let m = [
        {link: '/help#register', title: 'Регистрация'},
        {link: '/help#login', title: 'Вход'},
        {link: '/help#files', title: 'Файлы'},
        {link: '/help#editor', title: 'Редактор'},
        {link: '/help#users', title: 'Пользователи'},
        {link: '/help#tasks', title: 'Задания'},
        {link: '/help#architectures', title: 'Архитектуры'},
      ];
      if (this.isAdmin) {
        m.splice(2, 2);
      }
      else {
        m.splice(4, 1);
      }
      return m;
    }
  },
};
</script>

<style>
.sidebar {
  padding: 8px;
  flex: 1;
  overflow: auto;
  border-right: 1px solid #aaa;
  min-width: min-content;
}

.sidebar__logo {
  position: relative;
  left: 32px;
  height: 56px;
  margin: 8px;
}

.nav ul {
  list-style: none;
}

.nav__item {
  display: block;
  padding: 8px 16px;
  color: black;
  background-color: white;
  text-decoration: none;
  font-size: 13pt;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.nav__item:hover {
  background-color: var(--selection-color);
}

.nav__item_selected {
  color: white;
  background-color: var(--buttons-color);
}

.nav__item_selected:hover {
  background-color: var(--hover-button-color);
}

/* Second level navigation button */
li > ul .nav__item {
  font-size: 12pt;
  padding-left: 40px;
}
</style>
