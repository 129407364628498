<template>
<div class="fade">
  <div class="app-modal">
    <div v-if="waiting">
      <app-spinner/>
    </div>
    <div v-else class="app-modal__body">
      <slot name="body"></slot>
    </div>
    <div class="app-modal__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</div>
</template>

<script>
import AppSpinner from './app-spinner.vue';

export default {
  components: {
    AppSpinner,
  },
  props: {
    waiting: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style>
.fade {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.app-modal {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 24px -4px;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(max(-50%, -50vw), max(-50%, -50vh));
  padding: 24px;
}

.app-modal__header {
  grid-column: 1/-1;
  font-size: 16pt;
  font-weight: bold;
}

.app-modal__body {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  align-items: center;
}

.app-modal__label {
  grid-column: 1;
  text-align: right;
}

.app-modal__control {
  grid-column: 2;
}

.app-modal__footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-top: 24px;
}

.app-modal__footer > * {
  margin-left: 16px;
}

.app-modal__error {
  color: var(--failure-color);
  margin-top: -12px;
  max-width: 184px;
  font-size: 10pt;
  text-align: initial;
  grid-column: 2;
  padding: unset;
}
</style>
