<template functional>
  <div class="app-toolbar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppToolbar'
}
</script>

<style>
.app-toolbar {
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
  flex-shrink: 0;
  align-items: center;
  overflow: hidden;
  color: white;
  padding: 4px 8px;
  background-color: var(--header-color);
}

.app-toolbar__label {
	margin: 4px 0px 4px 16px;
}
</style>