<template>
  <button
    class="app-button-text"
    :type="type"
    :disabled="disabled"
    @click="$emit('click', $event)"
    v-text="text"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    text: {
      type: String,
      required: true,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style>
.app-button-text {
  font-weight: bold;
  color: var(--buttons-color);
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  padding: 4px;
}

.app-button-text:hover {
  color: var(--hover-button-color);
}

.app-button-text:active {
  color: var(--buttons-color);
}

.app-button-text:disabled {
  cursor: not-allowed;
}
</style>