<template>
  <app-modal :waiting="waiting">
    <template #body>
      <form class="app-modal__body"
        novalidate
        @submit.prevent="submit"
        id="login"
      >
        <h1
          class="app-modal__header"
          v-t="'view-login.logIn'"
        />

        <label
          class="app-modal__label"
          for="email" v-t="'view-login.login'"
        />
        <app-input
          class="app-modal__control"
          :class="{'app-input_invalid': $v.email.$dirty && $v.email.$invalid}"
          type="email"
          name="email"
          :placeholder="$t('view-login.login')"
          v-model.trim.lazy="$v.email.$model"
        />
        <p
          class="app-modal__error"
          v-if="$v.email.$dirty && !$v.email.required"
          v-t="'view-login.errors.email.required'"
        />
        <p
          class="app-modal__error"
          v-else-if="$v.email.$dirty && !$v.email.email"
          v-t="'view-login.errors.email.bad'"
        />

        <label class="app-modal__label" for="password" v-t="'view-login.password'"/>
        <app-input
          class="app-modal__control"
          :class="{'app-input_invalid': $v.password.$dirty && $v.password.$invalid}"
          type="password"
          name="password"
          :placeholder="$t('view-login.password')"
          v-model.trim.lazy="$v.password.$model"
        />
        <p
          class="app-modal__error"
          v-if="$v.password.$dirty && !$v.password.required"
          v-t="'view-login.errors.password.required'"
        />

        <label
          class="app-modal__label"
          for="remember"
          v-t="'view-login.remember'"
        />
        <app-switch
          class="app-modal__control"
          name="remember"
          v-model="remember"
        />

        <label
          class="app-modal__label"
          for="isAdmin"
          v-t="'view-login.administrator'"
        />
        <app-switch
          class="app-modal__control"
          name="isAdmin"
          v-model="isAdmin"
        />

        <!--Agreement-->
        <template v-if="agreementRequired">
          <i18n
            tag="label"
            class="app-modal__label"
            for="agreement"
            path="agreement.accept"
          >
            <template #agreement>
              <br/>
              <a :href="$t('agreement.href')" v-t="'agreement.agreement'"/>
            </template>
          </i18n>
          <app-switch
            class="app-modal__control"
            name="agreement"
            v-model="agreement"
          />
          <p
            class="app-modal__error"
            v-if="!agreement"
            v-t="'agreement.error'"
          />
        </template>

        <p
          class="app-modal__error"
          v-if="errorMessage"
          v-text="errorMessage"
        />
      </form>
    </template>
    <template #footer>
      <app-button-text
        :text="$t('view-login.registration')"
        @click="register"
      />
      <app-button
        type="submit"
        form="login"
        :text="$t('view-login.logIn')"
      />
    </template>
  </app-modal>
</template>

<script>
import {email, required} from 'vuelidate/lib/validators';
import {mapActions} from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      remember: false,
      errorMessage: null,
      isAdmin: false,
      agreement: false,
      agreementRequired: false,
      waiting: false,
    }
  },
  validations: {
    email: {
      email,
      required
    },
    password: {
      required,
    },
  },
  created() {
    this.$store.commit('CLEAR_MESSAGES');
  },
  methods: {
    ...mapActions(['login']),
    submit() {
      if(this.$v.$invalid) {
        this.$v.$touch();
      }
      else {
        this.errorMessage = null;
        this.agreementRequired = false;
        this.waiting = true;
        this.login(this.$data)
        .then(() => this.$router.push({name: 'home'}))
        .catch(err => {
          if(err.response) {
            switch(err.response.data.message) {
              case 'Account is not confirmed':
                this.errorMessage = this.$t('view-login.errors.authorization.notConfirmed');
                break;
              case 'You must accept agreement to use the system':
                this.agreementRequired = true;
                break;
              default:
                this.errorMessage = this.$t('view-login.errors.authorization.incorrect');
            }
          } else {
            this.errorMessage = this.$t('view-login.errors.connection');
          }
          this.waiting = false;
        });
      }
    },
    register() {
      this.$router.push({name: 'register'});
    }
  },
}
</script>
