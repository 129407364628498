import Axios from 'axios';
import appConfig from '@/app-config.js';
import Api from './v0.js';

const axios = Axios.create({
  baseURL: appConfig.backendUrl,
  timeout: appConfig.apiTimeout
});

export default new Api(axios);
