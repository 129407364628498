import api from '@/api/index.js';
import {patchObject} from '@/tools.js';
import {cloneDeep} from '../tools.js';

function removePasswords(userObj) {
  delete userObj.password;
  delete userObj.old_password;
}

export default {
  state: {
    userList: [],
    user: {},
    stat: {
      active: 0,
      total: 0
    },
  },
  getters: {
    userList(state) {
      return state.userList;
    },
    user(state) {
      return state.user;
    },
    usersStat(state) {
      return state.stat;
    },
  },
  mutations: {
    SET_USER_LIST(state, userList) {
      state.userList = userList;
      const user = userList.find(u => u.id === state.user.id);
      if(user)
        state.user = user;
    },
    SET_USER(state, user) {
      user = cloneDeep(user)
      removePasswords(user);
      state.user = user;
      const index = state.userList.findIndex(u => u.id === user.id);
      if(index !== -1)
        state.userList.splice(index, 1, user);
    },
    NEW_USER(state, user) {
      user = cloneDeep(user)
      removePasswords(user);
      state.userList.push(user);
    },
    DELETE_USER(state, id) {
      if(state.user.id === id)
        state.user = {};
      state.userList = state.userList.filter(u => u.id !== id);
    },
    SET_USER_STAT(state, stat) {
      state.stat = stat;
    }
  },
  actions: {
    async fetchUserList(ctx, query) {
      const r = await api.fetchUserList(query);
      if(r.status === 200) {
        ctx.commit('SET_USER_LIST', r.data.users);
        ctx.commit('SET_USER_STAT', r.data.stat);
      }
      return r;
    },
    async fetchUser(ctx, id) {
      const r = await api.fetchUser(id);
      if(r.status === 200)
        ctx.commit('SET_USER', r.data);
      return r;
    },
    async updateUser(ctx, user) {
      let r;
      const patch = patchObject(ctx.state.user, user);
      if(patch) {
        r = await api.updateUser(user.id, patch);
        if(r.status === 200)
          ctx.commit('SET_USER', user);
      }
      return r;
    },
    async newUser(ctx, user) {
      const r = await api.newUser(user);
      if(r.status === 200)
        ctx.commit('NEW_USER', user);
      return r;
    },
    async deleteUser(ctx, id) {
      const r = await api.deleteUser(id);
      if(r.status === 204)
        ctx.commit('DELETE_USER', id);
      return r;
    }
  },
};
