import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZoneName: 'short',
    },
  },
  ru: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZoneName: 'short',
    },
  },
};

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

/**
 * Pluralization rules for slavic languages.
 *
 * Format in locale files:
 * ```
 * 0 things |
 * things count ends with 1 |
 * things count ends with 2-4 |
 * things count ends with 5-9, 0 and teens (10-19)
 * ```
 *
 * @param choice {number} a choice index given by the input to $tc:
 * `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
 *
 * Source: official i18n guide.
 */
function slavicPluralization(choice, choicesLength) {
  if(choice === 0)
    return 0;
  const teen = 10 < choice && choice < 20;
  const endsWithOne = choice % 10 === 1;
  if(choicesLength < 4)
    return (!teen && endsWithOne) ? 1 : 2;
  if(!teen && endsWithOne)
    return 1;
  if(!teen && choice % 10 >= 2 && choice % 10 <= 4)
    return 2;
  return (choicesLength < 4) ? 2 : 3;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
  dateTimeFormats,
  pluralizationRules: {
    'ru': slavicPluralization,
  },
});

export default i18n;
