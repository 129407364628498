import api from '@/api/index.js';

export default {
  state() {
    return {
      fileList: [],
      file: {
        path: '',
        size: 0,
        modified: '',
      },
      stat: {
        used: 0,
        total: 0,
      },
    };
  },
  getters: {
    fileList(state) {
      return state.fileList;
    },
    file(state) {
      return state.file;
    },
    filesStat(state) {
      state.stat.used = state.fileList.reduce(
        (used, file) => used + file.size,
        0
      );
      return state.stat;
    },
  },
  mutations: {
    SET_FILE_LIST(state, fileList) {
      state.fileList = fileList;
    },
    SET_FILE_STAT(state,total)
    {
      state.stat.used = state.fileList.reduce(
        (used, file) => used + file.size,
        0
      );
      state.stat.total = total;
    },
    SET_FILE(state, file) {
      state.file = file;
    },
    ADD_FILE_TO_LIST(state, file) {
      let newFile = api.transformFileMetaB2F(file);
      state.fileList.push(newFile);
    },
    DELETE_FILE_FROM_LIST(state, path) {
      if (state.file.path === path)
        state.file = {};
      state.fileList = state.fileList.filter(f => f.path !== path);
    },
    UPDATE_FILE_META(state, file) {
      let dotPos = file.newPath.lastIndexOf('.');
      let extension = '';
      if (dotPos > 0)
        extension = file.newPath.slice(dotPos + 1);
      let currentFile = state.fileList.filter(f => f.path === file.currentPath)[0];
      currentFile.path = file.newPath;
      currentFile.extension = extension;
      if (file.size)
        currentFile.size = file.size;
      if (file.modified)
        currentFile.modified = file.modified;
    },
  },
  actions: {
    async fetchFileList(ctx) {
      const r = await api.fetchFileList();
      if (r.status === 200) {
        ctx.commit('SET_FILE_LIST', r.data.files);
        ctx.commit('SET_FILE_STAT', r.data.total);
      }
    },
    async fetchFileMeta(ctx, path) {
      const r = await api.fetchFileMeta(path);
      if (r.status === 200)
        ctx.commit('SET_FILE', r.data);
    },
    async uploadFile(ctx, {file, rewrite}) {
      const r = await api.uploadFile(file, rewrite);
      if (r.status === 200 || r.status === 201) {
        if (rewrite) {
          ctx.commit('UPDATE_FILE_META', {
            newPath: file.name,
            currentPath: file.name,
            size: file.size,
            modified: new Date().toISOString()
          });
        }
        else {
          ctx.commit('ADD_FILE_TO_LIST', file);
        }
      }
    },
    async updateFileMeta(ctx, file) {
      const r = await api.updateFileMeta(file);
      if (r.status === 200)
        ctx.commit('UPDATE_FILE_META', file);
    },
    async deleteFile(ctx, path) {
      const r = await api.deleteFile(path);
      if (r.status === 204)
        ctx.commit('DELETE_FILE_FROM_LIST', path);
    },
  },
};
