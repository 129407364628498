import {archName, archIndex} from '@/tools.js';

export default class ApiV0 {
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Set default authorization parameters.
   *
   * Provided username and password will be used for all requests.
   *
   * @param {String} username - user name for Basic Auth.
   * @param {String} password - password for Basic Auth.
   */
  setDefaultAuth(username, password) {
    this.axios.defaults.auth = { username, password };
  }

  /**
   * Delete default authorization.
   */
  deleteDefaultAuth() {
    delete this.axios.defaults.auth;
    delete this.isAdmin;
    delete this.email;
  }

  _paginateParams({offset = 0, limit = 10, sortby, ascending = true}) {
    let order_by = '';
    if(sortby)
      order_by = sortby + ' ' + (ascending ? 'asc' : 'desc');
    return { limit, offset, order_by };
  }

  /**
   * Send login request with the given user name and password.
   *
   * @param {String} username
   * @param {String} password
   * @param {Boolean} isAdmin
   */
  async login(username, password, isAdmin = false, agreement = false) {
    this.isAdmin = isAdmin;
    this.email = username;
    if(isAdmin) {
      return this.axios({
        method: 'post',
        url: '/admin/login',
        auth: { username, password }
      });
    }
    else {
      const r = await this.axios({
        method: 'post',
        url: '/login',
        data: {
          email: username,
          password: password,
          agreement: agreement,
        }
      });
      r.data = {
        username: r.data.account_sid,
        token: r.data.account_token
      };
      return r;
    }
  }

  logout() {}

  _activateUser(id, active) {
    return this.axios({
      method: active ? 'post' : 'delete',
      url: '/site/accounts/' + id + (active ? '/confirm' : '')
    });
  }

  _updateQuotas(id, quotas) {
    const quotasBackend = {
      cpu: quotas.cpu,
      memory: quotas.memory,
      disc_space: quotas.disk,
      max_time: quotas.time,
      priority: quotas.priority
    };
    return this.axios({
      method: 'put',
      url: '/site/accounts/' + id,
      data: quotasBackend
    });
  }

  _changePassword(id, {password, old_password}) {
    let request = {
      method: 'post',
      data: {
        password: password,
        confirm_password: password,
      }
    };
    if(this.isAdmin) {
      request.url = '/site/accounts/' + id + '/changepassword';
    } else {
      request.url = '/changepassword';
      request.auth = {
        username: this.email,
        password: old_password,
      }
    }
    return this.axios(request);
  }

  /**
   * Transfrom user object from backend to frontend representation.
   * @param {Object} userObj - object from backend to transform
   */
  transformUserB2F(userObj) {
    return {
      id: userObj.account_sid,
      email: userObj.email,
      name: userObj.first_name + ' ' + userObj.last_name,
      created: userObj.creation_time,
      active: userObj.confirmed === 1,
      created: userObj.creation_time,
      modified: userObj.update_time,
      password: '',
      quotas: {
        cpu: userObj.cpu,
        memory: userObj.memory,
        disk: userObj.disc_space,
        time: userObj.max_time,
        priority: userObj.priority
      }
    };
  }

  transformTaskB2F(taskObj) {
    return {
      id: taskObj.task_sid,
      user_id: taskObj.user_id,
      user_email: taskObj.user_email,
      file_path: taskObj.file_path,
      arch: archName(taskObj.arch),
      status: {
        'queued': 0,
        'running': 1,
        'completed': 2,
        'stopped': 3,
      }[taskObj.status],
      created: taskObj.creation_time,
      modified: taskObj.update_time
    };
  }

  transformFileMetaB2F(fileObj) {
    let path = fileObj.name;
    let dotPos = path.lastIndexOf('.');
    let extension = '';
    if(dotPos > 0)
      extension = fileObj.name.slice(dotPos + 1);
    let modified = '';
    if(fileObj.update_time)
      modified = fileObj.update_time;
    else
      modified = fileObj.lastModified;
    return {
      path,
      extension,
      size: fileObj.size,
      modified
    };
  }

  trasformArchB2F(archObj) {
    return {
      arch: archName(archObj.service_id),
      status: archObj.status,
      modified: archObj.update_time,
    }
  }

  /**
   * Fetch a list of users.
   */
  async fetchUserList(query) {
    const r = await this.axios({
      method: 'get',
      url: '/site/accounts',
      params: this._paginateParams(query)
    });
    const userList = r.data.users;
    if(userList) {
      r.data.users = userList.map(this.transformUserB2F);
      r.data.stat = {
        total: r.data.count,
        active: r.data.active,
      }
    }
    return r;
  }

  async fetchUser(id) {
    let url = '';
    if(this.isAdmin)
      url = '/site/accounts/' + id;
    else
      url = '/accounts';
    const r = await this.axios({
      method: 'get',
      url: url
    });
    r.data = this.transformUserB2F(r.data);
    return r;
  }

  async updateUser(id, userObj) {
    let r = {};
    if(userObj.active !== undefined)
      r = await this._activateUser(id, userObj.active);
    if(userObj.quotas)
      r = await this._updateQuotas(id, userObj.quotas);
    if(userObj.password)
      r = await this._changePassword(id, userObj);
    return r;
  }

  async newUser(userObj) {
    let r = await this.axios({
      method: 'post',
      url: '/users',
      data: {
        email: userObj.email,
        password: userObj.password,
        first_name: userObj.name,
        last_name: '',
        agreement: userObj.agreement,
      }
    });
    // Clear password to prevent updateUser() setting password twice:
    userObj.password = '';
    r = await this.updateUser(r.data.account_sid, userObj);
    return r;
  }

  async deleteUser(id) {
    return this.axios({
      method: 'delete',
      url: '/site/accounts/' + id + '/destroy'
    });
  }

  async newTask(taskObj) {
    const sid = this.axios.defaults.auth.username;
    return this.axios({
      method: 'post',
      url: '/accounts/' + sid + '/file-execute/' + encodeURIComponent(taskObj.file_path),
      data: { arch: archIndex(taskObj.arch) }
    });
  }

  async fetchTaskList(query) {
    const sid = this.axios.defaults.auth.username;
    let url = '';
    if(this.isAdmin)
      url = '/site/tasks';
    else
      url = '/accounts/' + sid + '/tasks';
    const r = await this.axios({
      method: 'get',
      url: url,
      params: this._paginateParams(query)
    });
    const taskList = r.data.tasks;
    if(taskList) {
      r.data.tasks = taskList.map(this.transformTaskB2F);
      r.data.stat = {
        total: r.data.count,
        queued: r.data.queued,
        running: r.data.running,
        completed: r.data.completed,
      };
    }
    if(r.status === 204)
      r.data = {
        tasks: [],
        stat: {
          total: 0,
          queued: 0,
          running: 0,
          completed: 0,
        },
      };
    return r;
  }

  async fetchTask(id) {
    const sid = this.axios.defaults.auth.username;
    let url = '';
    if(this.isAdmin)
      url = '/site/tasks/' + id;
    else
      url = '/accounts/' + sid + '/tasks/' + id;
    const r = await this.axios({
      method: 'get',
      url: url,
    });
    r.data = this.transformTaskB2F(r.data);
    return r;
  }
  async stopTask(id) {
    if(this.isAdmin)
      this.deleteTask(id);
    else
    {
      const sid = this.axios.defaults.auth.username;
      return this.axios({
        method: 'post',
        url: '/accounts/' + sid + '/file-stop/' + id,
      });
    }
  }

  async deleteTask(id) {
    const sid = this.axios.defaults.auth.username;
    let url = '';
    if(this.isAdmin)
      url = '/site/tasks/' + id;
    else
      url = '/accounts/' + sid + '/tasks/' + id;
    return this.axios({
      method: 'delete',
      url: url
    });
  }

  async fetchArchList() {
    const sid = this.axios.defaults.auth.username;
    let url = '/services';
    if(this.isAdmin)
      url = '/site' + url;
    else
      url = '/accounts/' + sid + url;
    const r = await this.axios({
      method: 'get',
      url: url,
    });
    r.data = r.data.map(this.trasformArchB2F);
    return r;
  }

  async fetchArch(arch) {
    const sid = this.axios.defaults.auth.username;
    let url = '/services/' + archIndex(arch);
    if(this.isAdmin)
      url = '/site' + url;
    else
      url = '/accounts/' + sid + url;
    const r = await this.axios({
      method: 'get',
      url: url,
    });
    r.data = this.trasformArchB2F(r.data);
    return r;
  }

  async updateArch({arch, status}) {
    let request = {url: '/site/services/' + archIndex(arch)};
    if(status === 0) {
      request.method = 'delete';
    }
    else {
      request.method = 'put';
      request.data = {status};
    }
    return this.axios(request);
  }

  // TODO: info tables

  async uploadFile(file, rewrite) {
    const sid = this.axios.defaults.auth.username;
    let formData = new FormData();
    formData.append('userUpload', file);
    if(rewrite)
      formData.append('replaceExisting', 'yes');
    return this.axios({
      method: 'post',
      url: '/accounts/' + sid + '/fileupload',
      data: formData
    });
  }

  async updateFileMeta(file) {
    const sid = this.axios.defaults.auth.username;
    const r = this.axios({
      method: 'put',
      url: '/accounts/' + sid + '/files/' + encodeURIComponent(file.currentPath),
      data: { name: file.newPath }
    });
    return r;
  }

  async downloadFile(path) {
    const sid = this.axios.defaults.auth.username;
    return this.axios({
      method: 'get',
      url: '/accounts/' + sid + '/file-download/' + encodeURIComponent(path),
      responseType: 'blob',
    });
  }

  async fetchFileList() {
    const sid = this.axios.defaults.auth.username;
    const r = await this.axios({
      method: 'get',
      url: '/accounts/' + sid + '/files'
    });
    const t = await this.fetchUser(sid);
    const fileList = r.data;
    r.data = {
      files: fileList ? fileList.map(this.transformFileMetaB2F) : [],
      total: t.data.quotas.disk * 1048576,
    }
    return r;
  }

  async fetchFileMeta(path) {
    const sid = this.axios.defaults.auth.username;
    const r = await this.axios({
      method: 'get',
      url: '/accounts/' + sid + '/files/'+ encodeURIComponent(path),
    });
    r.data = this.transformFileMetaB2F(r.data);
    return r;
  }

  async deleteFile(path) {
    const sid = this.axios.defaults.auth.username;
    return this.axios({
      method: 'delete',
      url: '/accounts/' + sid + '/files/' + encodeURIComponent(path)
    });
  }

  async compile(fileObj) {
    const sid = this.axios.defaults.auth.username;
    fileObj.arch = archIndex(fileObj.arch);
    return this.axios({
      method: 'post',
      url: '/accounts/' + sid + '/file-compile',
      data: fileObj
    });
  }
}
