import api from '@/api';
import {fromStorages, toStorage} from '@/tools.js';

export default {
  state() {
    return {
      username: fromStorages('username', ''),
      token: fromStorages('token', ''),
      isAdmin: fromStorages('isAdmin', false),
    }
  },
  getters: {
    rememberAuth(state) {
      return !!localStorage.getItem('username');
    },
    isAuthenticated(state) {
      return !!state.username && !!state.token;
    },
    isAdmin(state) {
      return state.isAdmin;
    }
  },
  mutations: {
    INIT_AUTH(state) {
      if(state.username && state.token)
        api.setDefaultAuth(state.username, state.token);
      api.isAdmin = state.isAdmin; // TODO: fix with new backend
    },
    LOGIN(state, {username, token, remember, isAdmin}) {
      api.setDefaultAuth(username, token);
      const subState = {
        username,
        token,
        isAdmin
      };
      state = Object.assign(state, subState);
      toStorage(remember ? localStorage : sessionStorage, subState);
    },
    LOGOUT(state) {
      api.deleteDefaultAuth();
      state.username = '';
      state.token = '';
      localStorage.clear();
      sessionStorage.clear();
    }
  },
  actions: {
    async login(ctx, {email, password, remember, isAdmin, agreement}) {
      let r = await api.login(email, password, isAdmin, agreement);
      ctx.commit('LOGIN', {
        username: isAdmin ? email : r.data.username,
        token: r.data.token,
        remember: remember,
        isAdmin: isAdmin,
      })
    },
    async logout(ctx) {
      await api.logout();
      ctx.commit('LOGOUT');
    },
  },
}