import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './auth.js'
import Messages from './messages.js'
import Users from './users.js'
import Tasks from './tasks.js'
import Files from './files.js'
import Console from './console.js'
import Archs from './archs.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Messages,
    Users,
    Tasks,
    Files,
    Console,
    Archs,
  }
})
