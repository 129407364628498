import api from '@/api/index.js';

export default {
  state() {
    return {
      taskList: [],
      task: {
        id: '',
        user_id: '',
        user_email: '',
        file_path: '',
        arch: 0,
        status: 0,
        created: new Date().toISOString(),
        modified: new Date().toISOString()
      },
      stat: {
        queued: 0,
        running: 0,
        completed: 0,
        total: 0,
      },
    }
  },
  getters: {
    taskList(state) {
      return state.taskList;
    },
    task(state) {
      return state.task;
    },
    tasksStat(state) {
      return state.stat;
    }
  },
  mutations: {
    SET_TASK_LIST(state, taskList) {
      state.taskList = taskList;
      const task = taskList.find(t => t.id === state.task.id);
      if (task)
        state.task = task;
    },
    SET_TASK(state, task) {
      state.task = task;
    },
    NEW_TASK(state, task) {
      const now = new Date().toISOString();
      task.status = 0;
      task.created = now;
      task.modified = now;
      state.taskList.push(task);
    },
    STOP_TASK(state,id)
    {
      if (state.task.id === id)
        state.task.status=2;
    },
    DELETE_TASK(state, id) {
      if (state.task.id === id)
        state.task = {};
      state.taskList = state.taskList.filter(t => t.id !== id);
    },
    SET_TASK_STAT(state, stat) {
      state.stat = stat;
    }
  },
  actions: {
    async fetchTaskList(ctx, query) {
      const r = await api.fetchTaskList(query);
      if (r.status === 200 || r.status === 204) {
        ctx.commit('SET_TASK_LIST', r.data.tasks);
        ctx.commit('SET_TASK_STAT', r.data.stat);
      }
      return r;
    },
    async fetchTask(ctx, id) {
      const r = await api.fetchTask(id);
      if (r.status === 200)
        ctx.commit('SET_TASK', r.data);
      return r;
    },
    async newTask(ctx, task) {
      const r = await api.newTask(task);
      if (r.status === 200)
        ctx.commit('NEW_TASK', task);
      return r;
    },
    async stopTask(ctx,id)
    {
      const r = await api.stopTask(id);
      if(r.status===200)
        ctx.commit('STOP_TASK', id);
      return r;
    },
    async deleteTask(ctx, id) {
      const r = await api.deleteTask(id);
      if (r.status === 204)
        ctx.commit('DELETE_TASK', id);
      return r;
    }
  },
}
