<template>
  <div
    class="app-input"
    :class="{'app-input_disabled': disabled}"
  >
    <input
      :placeholder="placeholder"
      @change="$emit('change', $event.target.value)"
      :value="value"
      :type="type"
      :id="name"
      :disabled="disabled"
      :readonly="readonly"
      v-focus="focus"
    />
    <span
      v-if="postfix.length > 0"
      class="app-input__postfix"
      v-text="postfix"
    />
  </div>
</template>

<script>
export default {
  directives: {
    focus: {
      // custom directive for autofocus
      inserted: function (el) {
        el.focus();
      },
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    //Value from database
    value: {
      type: [String, Number],
      required: true,
    },
    //Type of input
    type: {
      type: String,
      default: 'text',
    },
    //Name of label for assigning to input
    name: {
      type: String,
      default: '',
      required: true,
    },
    //Placeholder for inputs
    placeholder: {
      type: String,
    },
    //Postfix for measurement unit (if presents)
    postfix: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.app-input input {
  min-width: 0px;
  max-width: 170px;
  padding-right: 8px;
  border: none;
}

.app-input input::-webkit-outer-spin-button,
.app-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app-input input[type='number'] {
  -moz-appearance: textfield;
}

.app-input {
  border: 1px solid #aaa;
  border-radius: 3px;
  display: flex;
  max-width: 184px;
  padding: 4px 8px;
  align-items: baseline;
}

.app-input:focus-within {
  /* Fix for Firefox since it calculates border and box-shadow differently,
   * so avoid simultaneously visible border and box-shadow
   */
  border: 1px solid transparent;
  box-shadow: 0 0 0 2px var(--buttons-color);
}

.app-input__postfix {
  margin-left: auto;
}

.app-input_invalid {
  border: 1px solid var(--failure-color);
}

.app-input_valid {
  border: 1px solid var(--success-color);
}

.app-input_disabled,
.app-input input:disabled {
  cursor: not-allowed;
}
</style>
