<template>
  <button
    class="app-button"
    :class="{'app-button_square': !text}"
    :disabled="disabled"
    @click="$emit('click', $event)"
    type="button"
  >
    <span v-if="!!this.$slots.default"
      class="app-button__slot"
    >
      <slot/>
    </span>
    <span v-else-if="icon"
      class="icon"
      aria-hidden="true"
      v-text="icon"
    />
    <span
      v-if="text"
      class="app-button__text_margin"
      v-text="text"
    />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.app-button {
  border: none;
  height: 32px;
  margin: 4px 8px;
  border-radius: 3px;
  padding: 0px 16px;
  font-size: 13pt;
  background-color: var(--buttons-color);
  color: white;
  white-space: nowrap;
  align-self: flex-start;
  cursor: pointer;
}

.app-button:enabled:hover {
  color: white;
  background-color: var(--hover-button-color);
}

.app-button:enabled:active {
  color: white;
  background-color: var(--active-button-color);
}

.app-button:disabled {
  cursor: default;
  background-color: var(--disabled-button-color);
}

.app-button_outlined {
  color: var(--buttons-color);
  background-color: transparent;
  border: 1px solid var(--buttons-color);
}

.app-button_outlined:enabled:hover {
  border-color: var(--hover-button-color);
}

.app-button_outlined:disabled {
  color: var(--disabled-button-color);
  background-color: transparent;
  border: 1px solid var(--disabled-button-color);
}

.app-button_square {
  width: 32px;
  padding: 0px;
}

* + .app-button__text_margin {
  margin-left: 8px;
}

.app-button__slot {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}
</style>