import appConfig from './app-config';
import i18n from './i18n';

/**
 * Convert architecture index to its name
 * @param {Integer} index - architecture index
 */
export function archName(index) {
  let name = appConfig.archs[index];
  if(name === undefined)
    name = index.toString();
  return name;
}

/**
 * Convert architecture name to its index
 * @param {String} name - architecture name
 */
export function archIndex(name) {
  const a = appConfig.archs
  let index = Object.keys(a).find(index => a[index] === name);
  if(index === undefined)
    index = -1;
  else
    index = parseInt(index);
  return index;
}

/**
 * Convert task status index to its name taking into account locale
 * @param {Integer} index - status index
 */
export function taskStatusName(index) {
  switch(index) {
    case 0:
      return i18n.t('taskStatus.queued');
    case 1:
      return i18n.t('taskStatus.running');
    case 2:
      return i18n.t('taskStatus.completed');
    case 3:
      return i18n.t('taskStatus.stopped');
    default:
      return index.toString();
  }
}

/**
 * Format bytes in current locale in short form (with unit 'B')
 * @param {Number} b - number of bytes
 */
export function sbytes(b) {
  return b !== undefined ? i18n.n(b) + ' ' + i18n.t('measurement.information.B') : '';
}

/**
 * Format bytes in current locale in long form (with unit 'bytes')
 * @param {Number} b - number of bytes
 */
export function lbytes(b) {
  return b !== undefined ? i18n.n(b) + ' ' + i18n.tc('measurement.information.bytes', b): '';
}

/**
 * Format bytes in human-readable form with units (e.g, KiB, MiB, GiB)
 * @param {Number} b - number of bytes
 * @param {Number} fracDigits - number of fractional digits
 */
export function hbytes(b, fracDigits = 2) {
  if(b === undefined)
    return '';
  let unit = 'B';
  if(b >= 1073741824) {
    b /= 1073741824;
    unit = 'GiB';
  } else if(b >= 1048576) {
    b /= 1048576;
    unit = 'MiB';
  } else if(b >= 1024) {
    b /= 1024;
    unit = 'KiB';
  }
  if(unit !== 'B')
    return i18n.n(Number(b).toFixed(fracDigits)) + ' ' +
      i18n.t('measurement.information.' + unit);
  else
    return sbytes(b);
}

/**
 * Convert datetime from ISO 8601 to format based on current locale and time
 * zone
 *
 * @param {String} timeStamp - date and time string in ISO 8601 format, e.g.,
 * 2020-10-29T18:36:15Z
 */
export function dateTimeFormat(timeStamp) {
  const date = new Date(timeStamp);
  if(isFinite(date))
    return i18n.d(date, 'long');
  else
    return timeStamp;
}

/**
 * Get value from either Session or Local storages.
 *
 * @param {string} key - key to retrieve from storage. First, Session Storage is
 * tried, then Local Storage is inspected.
 * @param {any} onMissing - value if both Session and Local Storages do not
 * contain the given key.
 * @returns Found value or `onMissing`. Boolean values are converted
 * automatically.
 */
export function fromStorages(key, onMissing = null) {
  const val = sessionStorage.getItem(key) || localStorage.getItem(key) || onMissing;
  if(val === 'true')
    return true;
  else if(val === 'false')
    return false;
  else
    return val;
}

/**
 * Save object to either Local or Session Storage.
 *
 * @param {Object} storage - either localStorage or sessionStorage
 * @param {Object} obj - object to store. Each field is stored independently.
 *
 * If field is stored, e.g., in Local Storage, then it is removed from Session
 * Storage and vice versa.
 */
export function toStorage(storage, obj) {
  const remStorage = storage === localStorage ? sessionStorage : localStorage;
  for(const prop in obj) {
    storage.setItem(prop, obj[prop]);
    remStorage.removeItem(prop);
  }
}

/**
 * Check whether the given item is object.
 *
 * @param {any} item - item to check.
 * @returns `true` if `item` is object, `false` otherwise.
 */
export function isObject(item) {
  return item && typeof(item) === 'object';
}

/**
 * Modify new object such that it only contains properties with values which are
 * different from the old ones.
 *
 * @param {Object} oldObj - reference object with old values.
 * @param {Object} newObj - new object that is modified in place.
 *
 * This function assumes that all objects are (possibly) nested objects of
 * primitive values that can be compared safely using strict compatison
 * operator (===). Therefore, patch for objects with array properties may work
 * unexpectedly.
 *
 * @see patchObject
 */
export function patchObjectInplace(oldObj, newObj) {
  for(let key in newObj) {
    let oldVal = oldObj[key];
    let newVal = newObj[key];
    if(isObject(newVal)) {
      patchObjectInplace(oldVal, newVal);
      if(Object.keys(newVal).length === 0)
        delete newObj[key]; // Delete if value is an empty object
    } else if(newVal === oldVal) {
      delete newObj[key]; // Delete if values are equal
    }
  }
}

/**
 * This function is like `patchObjectInplace` except that it does not modify
 * its arguments. The patch object is returned as a result.
 *
 * @param {Object} oldObj - object with old values.
 * @param {Object} newObj - object with new values.
 * @returns Patch object or `null` if `oldObj` and `newObj` contain the same
 * data.
 * @see patchObjectInplace
 */
export function patchObject(oldObj, newObj) {
  let patchObj = {};
  for(let key in newObj) {
    const oldVal = oldObj[key];
    const newVal = newObj[key];
    if(isObject(newVal)) {
      const patchVal = patchObject(oldVal, newVal);
      if(patchVal)
        patchObj[key] = patchVal;
    } else if(newVal !== oldVal) {
      patchObj[key] = newVal;
    }
  }
  if(Object.keys(patchObj).length > 0)
    return patchObj;
  else
    return null;
}

/**
 * Return a deep clone of the object.
 *
 * @param {Object} obj - object to clone.
 *
 * This function is only valid for objects that can be safely stringified to
 * JSON without data loss.
 */
export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Replace properties in the target object by the source ones if they exist.
 *
 * @param {Object} target - object for assigning. This argument is modified in
 * place.
 * @param {Object} source - object to take values from.
 * @returns Reference to the target object.
 *
 * Target object never expands, i.e., no new properties are added.
 */
export function replace(target, source) {
  if(!source)
    return target;
  for(let key in target) {
    const sval = source[key];
    const tval = target[key];
    if(isObject(tval))
      target[key] = replace(tval, sval);
    else if(sval)
      target[key] = sval;
  }
  return target;
};

/**
 * Get base file name given the path.
 *
 * @param {String} path - path with optional slashes '/'.
 * @returns Base file name.
 */
export function basename(path) {
  return path.slice(path.lastIndexOf('/') + 1);
}

/**
 * Get extension of the file given the path.
 *
 * @param {String} path - path with optional slashes '/'.
 * @returns File extension.
 *
 * File names starting with the dot, e.g., '.hidden', have empty extension.
 */
export function fileExtension(path) {
  const filename = basename(path);
  const pos = filename.lastIndexOf('.')
  return pos > 0 ? filename.slice(pos + 1) : '';
}