import appConfig from '@/app-config.js';
import {fromStorages} from '@/tools.js';

export default {
  state() {
    return {
      isOpened: fromStorages('isConsoleOpened', true),
      messages: [],
    }
  },
  getters: {
    isConsoleOpened(state) {
      return state.isOpened;
    },
    consoleMessages(state) {
      return state.messages;
    },
  },
  mutations: {
    ADD_CONSOLE_MESSAGE(state, msg) {
      if(state.messages.length >= appConfig.consoleLimit)
        state.messages.splice(0, 1);
      state.messages.push(msg);
    },
    CLEAR_CONSOLE(state) {
      state.messages.splice(0);
    },
    TOGGLE_CONSOLE(state) {
      state.isOpened = !state.isOpened;
      localStorage.setItem('isConsoleOpened', state.isOpened);
    }
  },
}