import appConfig from '@/app-config.js';

/**
 * Vuex module for storing various messages, e.g., notifications or errors.
 * The messages are displayed by `the-messagebox` component.
 *
 * Usage in components:
 * `this.$store.dispatch('addMessage', msg)` adds message.
 *
 * Message object `msg` has the following structure:
 * ```
 * {
 *   text: String,
 *   type: String,
 * }
 * ```
 * Available types are: `notification`, `error`.
 */

export default {
  state: function() {
    return {
      messages: [],
      nextMessageId: 0,
    }
  },
  getters: {
    messages(state) {
      return state.messages;
    }
  },
  mutations: {
    ADD_MESSAGE(state, msg) {
      msg.id = state.nextMessageId++;
      state.messages.push(msg);
    },
    DELETE_MESSAGE(state, msg) {
      const index = state.messages.indexOf(msg)
      if(index >= 0)
        state.messages.splice(index, 1);
    },
    CLEAR_MESSAGES(state) {
      state.messages.splice(0);
      state.nextMessageId = 0;
    }
  },
  actions: {
    // Add message and auto hide if applicable
    addMessage(ctx, msg) {
      ctx.commit('ADD_MESSAGE', msg);
      const timeout = appConfig.messageAutoHideTimeout;
      if(timeout > 0 && msg.type === 'notification')
        setTimeout(() => {ctx.commit('DELETE_MESSAGE', msg)}, timeout);
    }
  }
}