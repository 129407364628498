import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import basicRoutes from './basic-routes.js';

Vue.use(VueRouter);

 function homeRedirect(to) {
   let locale = to.params.locale || 'en';
   let path = '/' + locale;
   path += store.getters.isAdmin ? '/users' : '/files';
   return path;
 }

 /**
 * The following meta parameters may be used in the route configuration:
 * @param {Boolean} hideNavigation - specifies whether the navigation menu is
 * hidden for the given route. Default `false`.
 * @param {Boolean} noAuth - specifies whether authorization is not required to
 * visit the given route. Default `false`.
 */

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'nav__item_selected',
  routes: [
    {
      name: 'home',
      path: '/',
      redirect: to => store.getters.isAdmin ? '/users' : '/files',
    },
    ...basicRoutes,
    {
      name: 'logout',
      path: '/logout',
      beforeEnter(to, from, next) {
        store.dispatch('logout')
        .then(next({name: 'login'}))
        .catch(err => next(false));
      }
    },
    { // Error 404 route must be the last one
      path: '*',
      component: () => import(/* webpackChunkName: "error404" */ '@/views/view-error404.vue'),
      meta: {
        hideNavigation: true,
        noAuth: true,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if( // Authentication
    to.matched.some(route => !route.meta.noAuth) &&
    !store.getters.isAuthenticated
  ) {
    next({name: 'login'});
  } else
    next();
})

export default router;