import appConfig from '@/app-config.js';

import ViewLogin from '@/views/view-login.vue';

// Lazy component loading:
const ViewRegister = () => import(/* webpackChunkName: "register" */ '@/views/view-register.vue');

const ViewUserList = () => import(/* webpackChunkName: "users" */ '@/views/view-user-list.vue');
const ViewUserAdd = () => import(/* webpackChunkName: "users" */ '@/views/view-user-add.vue');
const ViewUser = () => import(/* webpackChunkName: "users" */ '@/views/view-user.vue');

const ViewTaskList = () => import(/* webpackChunkName: "tasks" */ '@/views/view-task-list.vue');
const ViewTask = () => import(/* webpackChunkName: "tasks" */ '@/views/view-task.vue');

const ViewFileList = () => import(/* webpackChunkName: "files" */ '@/views/view-file-list.vue');
const ViewFile = () => import(/* webpackChunkName: "files" */ '@/views/view-file.vue');

const ViewSettings = () => import(/* webpackChunkName: "settings" */ '@/views/view-settings.vue');
const ViewHelp = () => import(/* webpackChunkName: "help" */ '@/views/view-help.vue');

const ViewEditor = () => import(/* webpackChunkName: "editor" */ '@/views/view-editor.vue')

export default [
  {
    name: 'login',
    path: '/login',
    component: ViewLogin,
    meta: {
      hideNavigation: true,
      noAuth: true,
    },
  },
  {
    name: 'register',
    path: '/register',
    component: ViewRegister,
    meta: {
      hideNavigation: true,
      noAuth: true,
    },
  },
  {
    name: 'userList',
    path: '/users',
    component: ViewUserList,
    children:[
      {
        name: 'userAdd',
        path: 'add',
        component: ViewUserAdd,
      },
      {
        name: 'user',
        path: ':id',
        component: ViewUser,
        props: true,
      }
    ]
  },
  {
    name: 'fileList',
    path: '/files',
    component: ViewFileList,
    children: [
      {
        name: 'file',
        path: '*',
        component: ViewFile,
        props: true,
      }
    ]
  },
  {
    name: 'taskList',
    path: '/tasks',
    component: ViewTaskList,
    children: [
      {
        name: 'task',
        path: ':id',
        component: ViewTask,
        props: true,
      }
    ]
  },
  {
    name: 'archs',
    path: '/archs',
    redirect: {name: Object.values(appConfig.archs)[0]},
    component: () => import(/* webpackChunkName: "archs" */ '@/views/view-archs.vue'),
    children: Object.values(appConfig.archs).map(arch => ({
      name: arch,
      path: arch,
      component: () => import(/* webpackChunkName: "arch" */ '@/views/archs/' + arch + '.vue')
    }))
  },
  {
    name: 'settings',
    path: '/settings',
    component: ViewSettings
  },
  {
    name: 'help',
    path: '/help',
    component: ViewHelp
  },
  {
    name: 'editor',
    path: '/editor/*',
    component: ViewEditor,
    props: true,
    meta: {
      hideNavigation: true,
    },
  },
]