import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

// Register simple components globally
import AppButtonText from '@/components/app-button-text.vue';
import AppButton from '@/components/app-button.vue';
import AppInput from '@/components/app-input.vue';
import AppModal from '@/components/app-modal.vue';
import AppSelect from '@/components/app-select.vue';
import AppSwitch from '@/components/app-switch.vue';
import AppToolbar from '@/components/app-toolbar.vue';
Vue.component('app-button-text', AppButtonText);
Vue.component('app-button', AppButton);
Vue.component('app-input', AppInput);
Vue.component('app-modal', AppModal);
Vue.component('app-select', AppSelect);
Vue.component('app-switch', AppSwitch);
Vue.component('app-toolbar', AppToolbar);

// Config
Vue.config.productionTip = false;

// Init
store.commit('INIT_AUTH');

// Use plugins
import Vuelidate from 'vuelidate';
import {AppConfigPlugin} from './app-config';
Vue.use(Vuelidate);
Vue.use(AppConfigPlugin);

// Create new Vue instance
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
